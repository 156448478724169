import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
export default class BlogList extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    return (
      <Layout>
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return <div key={node.fields.slug}>{title}</div>
        })}
      </Layout>
    )
  }
}
export const blogListQuery = graphql`
  query blogListQueryPage($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
      filter: {fileAbsolutePath: {regex: "/blog/"}, frontmatter: {publish: {eq: true}}}
    ) {
              edges {
                node {
                  excerpt
                  fields {
                    slug
                  }
                  frontmatter {
                    date(formatString: "DD.MM.YYYY")
                    title
                    description
                    publish
                    coverImage {
                      publicURL
                      childImageSharp {
                        fluid(maxWidth: 500, quality: 90) {
                          src
                          srcSet
                          aspectRatio
                          sizes
                          base64
                        }
                      }
                    }
                  }
                }
              }
            }
          }
`